@font-face {
  font-family: 'Belwe Std Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 40rem;
  src: url('./fonts/Belwe Std Bold.otf'),
       url('./fonts/Belwe Std Bold.ttf');
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
